.timepicker {
  .timepicker__header {
    height: auto;

    .timepicker-dial__control {
      width: 70px;
    }
  }

  .timepicker__main-content {
    .timepicker__actions {
      display: flex;
      align-items: center;
      gap: 8px;

      button {
        min-width: 100px;
        min-height: 40px;
      }
    }
  }
}
