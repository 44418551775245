@import 'variables';

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@mixin ng-select-facet-values {
}
@mixin table-base-styles {
  th {
    border-bottom: 1px solid var(--color-table-header-border);
    color: var(--color-weight-700);
    font-size: var(--font-size-xs);
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    white-space: nowrap;
    background-color: transparent;
  }

  th,
  td {
    padding: calc(var(--space-unit) * 1.5) calc(var(--space-unit) * 1);
    color: var(--color-text-100);
  }

  tr td:first-of-type,
  tr th:first-of-type {
    text-align: center;
    @media screen and (min-width: $breakpoint-medium) {
      padding-inline-start: var(--surface-margin-left);
      text-align: start;
    }
  }

  th:last-of-type,
  td:last-of-type {
    border-inline-end: 1px solid var(--color-table-header-border);
  }

  tr:first-of-type th:last-of-type {
    border-image: linear-gradient(
        0deg,
        var(--color-table-header-border),
        transparent
      )
      1;
  }
  tr:last-of-type td:last-of-type {
    border-image: linear-gradient(
        180deg,
        var(--color-table-header-border),
        transparent
      )
      1;
  }

  tbody td {
    border-bottom: 1px solid var(--color-table-row-separator);
  }

  tbody tr:hover {
    background-color: var(--color-table-row-hover-bg);
  }
}

@mixin flex_center($align: center, $justify: center) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}
@mixin flex_flow($direction: row, $wrap: wrap) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
}
@mixin font_size_height($size: 16px, $lineHeight: 20px) {
  font-size: $size;
  line-height: $lineHeigh;
}

@mixin text_style($size: 16px, $weight: 400, $lineHeight: 20px) {
  font-size: $size;
  font-weight: $weight;
  line-height: $lineHeight;
}

@mixin text_bold($size: 16px, $weight: 700, $lineHeight: 20px) {
  font-size: $size;
  font-weight: $weight;
  line-height: $lineHeight;
}

@mixin macos_scrollbar($height) {
  &::-webkit-scrollbar {
    background-color: #fff;
    width: $height * 4;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: $height * 4;
    border: $height solid #d3d3d3;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}
