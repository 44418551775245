select.select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  &.primary {
    padding: 10px 20px !important;
    border-radius: 8px !important;
    color: var(--color-grey-900);
    height: 100%;
    font-weight: 400;
    height: 42px;

    &::placeholder {
      color: var(--color-grey-400);
      font-size: var(--font-size-sm);
      line-height: 1rem;
      font-weight: 300;
    }

    &.select-filter {
      padding: 6px 35px 6px 14px !important;
    }
  }

  &.read-only {
    background-color: var(--color-white-f7);
    color: var(--color-grey-900);
    pointer-events: none;
  }
}
