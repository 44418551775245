.text-primary {
  color: var(--color-primary-600) !important;
}

.text-base {
  font-size: var(--font-size-base) !important;
}

.m-0 {
  margin: 0 !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.rounded-full {
  border-radius: 100rem !important;
}
