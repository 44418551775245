.button,
.btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  padding: var(--space-unit) calc(var(--space-unit) * 1.5);
  font-size: var(--font-size-sm) !important;
  gap: var(--space-unit);
  border: none;
  border-radius: 12px;
  // height: 38px;
  cursor: pointer;
  min-width: 165px;
  padding: 0 12px;
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 1px 4px rgba(41, 12, 12, 0.03),
    0px 2px 6px rgba(0, 0, 0, 0.03),
    0px 2px 11px rgba(0, 0, 0, 0.04);
  background-color: var(--color-button-bg);
  color: var(--color-weight-700);
  line-height: var(--cds-global-typography-body-line-height) !important;

  &:link,
  &:visited {
    color: var(--color-weight-700);
  }

  &:disabled {
    background-color: var(--color-weight-100);
    color: var(--color-weight-500);
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background-color: var(--color-button-hover-bg);
    color: var(--color-weight-800);
  }
}

.button-ghost {
  display: inline-flex;
  white-space: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 var(--space-unit);
  height: calc(var(--space-unit) * 3);
  font-size: var(--font-size-xs);
  gap: 4px;
  border: 1px solid var(--color-weight-300);
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  white-space: nowrap;
  color: var(--color-button-ghost-text);
  background-color: var(--color-button-ghost-bg);

  &:hover {
    background-color: var(--color-button-ghost-hover-bg);
    color: var(--color-button-ghost-hover-text);
  }
}

a.button-ghost:link,
a.button-ghost:visited {
  color: var(--color-button-ghost-text);
}

a.button-ghost:hover {
  color: var(--color-button-ghost-hover-text);
}

.button-small {
  display: inline-flex;
  white-space: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 var(--space-unit);
  height: 32px;
  font-size: var(--font-size-xs);
  background-color: var(--color-button-small-bg);
  color: var(--color-button-small-text);
  gap: 4px;
  border: none;
  border-radius: var(--border-radius-lg);
  cursor: pointer;

  &:not(:disabled):hover {
    background-color: var(--color-button-small-hover-bg);
  }
}

.button,
.btn,
.button-small {
  &.primary,
  &.btn-primary {
    &:not(.btn-outlined):not(.outlined) {
      background: linear-gradient(90deg, var(--color-primary-400) 30%, var(--color-primary-600) 95%);
      padding: 8px 16px;
      font-size: var(--font-size-base);
      font-weight: 600;
      border-radius: 12px;
      color: white;
    }

    &:not(:disabled):hover {
      background-color: var(--color-primary-800);
      color: white;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.btn-outlined,
    &.outlined {
      background-color: transparent;
      color: var(--color-primary-400);
      border: 1px solid var(--color-primary-400);
      border-radius: 8px;
      padding: 6px 8px;

      &:not(:disabled):hover {
        background-color: var(--color-primary-400);
        color: white;
      }
    }
  }

  &.single,
  &.btn-single {
    font-size: var(--font-size-base);
    font-weight: 600;
    border-radius: 8px;
    min-width: auto;
    font-weight: 400;
    padding: 8px 18px !important;

    &:not(:disabled):hover {
      opacity: 0.9;
    }

    &:has(:disabled) {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.btn-outlined,
    &.outlined {
      background-color: transparent;
      color: currentColor;
      border: 1px solid currentColor;

      &:not(:disabled):hover {
        background-color: currentColor;
        color: white;
      }
    }
  }

  &.outlined,
  &.btn-outlined {
    padding: 8px 16px;
    font-size: var(--font-size-base);
    font-weight: 600;
    border-radius: 12px;
    color: var(--color-grey-700);
    background-color: transparent;
    box-shadow: none;
    border: 1px solid var(--color-grey-600);

    &:not(:disabled):hover {
      background-color: var(--color-grey-600);
      color: white;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &.success,
  &.btn-success {
    &:not(:disabled) {
      background-color: var(--color-primary-400);
      color: white;
      min-width: auto !important;
      border-radius: 8px;
      font-weight: 400;

      &:hover {
        opacity: 0.9;
        color: white;
      }

      &.btn-outlined,
      &.outlined {
        background-color: transparent;
        color: var(--color-primary-400);
        border: 1px solid var(--color-primary-400);

        &:hover {
          background-color: var(--color-primary-400);
          color: white;
        }
      }
    }
  }

  &.warning,
  &.btn-warning {
    &:not(.btn-outlined):not(.outlined) {
      background-color: var(--color-warning-700);
      padding: 8px 16px;
      font-size: var(--font-size-base);
      font-weight: 600;
      border-radius: 12px;
      color: white;

      &:hover {
        background-color: var(--color-warning-800);
        padding: 8px 16px;
        font-size: var(--font-size-base);
        font-weight: 600;
        border-radius: 12px;
        color: white;
      }

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  &.danger,
  &.btn-danger {
    &:not(:disabled) {
      background-color: var(--color-error-500);
      color: white;
      min-width: auto !important;
      border-radius: 8px;
      font-weight: 400;

      &:hover {
        opacity: 0.9;
        color: white;
      }

      &.btn-outlined,
      &.outlined {
        background-color: transparent;
        color: var(--color-error-500);
        border: 1px solid var(--color-error-500);

        &:hover {
          background-color: var(--color-error-500);
          color: white;
        }
      }
    }

    &.btn-outlined,
    &.outlined {
      background-color: transparent;
      color: var(--color-error-500);
      border: 1px solid var(--color-error-500);

      &:hover {
        background-color: var(--color-error-500);
        color: white;
      }
    }
  }

  &.secondary,
  &.btn-secondary {
    min-height: 42px;
    background-color: var(--color-secondary-400);

    &:hover {
      background-color: var(--color-secondary-500);
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}

a.button-small:link,
a.button-small:visited {
  color: var(--color-button-small-text);
}
