@import '../mixins';

.mat-date-range-input-outside {
  min-width: 234px;
  height: 38px;
  @include flex_center;
  gap: 8px;
  padding: 6px 12px;
  border: 1px solid var(--color-weight-200);
  border-radius: 6px;
  color: var(--color-grey-900);

  .mat-date-range-input-icon {
    margin-top: -2px;
    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
    }
  }

  .mat-date-range-input-container {
    .mat-date-range-input-separator-hidden {
      opacity: 1;
    }

    input:not([type='checkbox']):not([type='radio']) {
      background-color: transparent;
      border: none;
      padding: 0 !important;
      color: var(--color-grey-900);

      &::placeholder {
        color: var(--color-grey-400);
        font-size: var(--font-size-sm);
        font-weight: 300;
      }
    }
  }
}

.mat-calendar {
  font-family: BeVietnamPro, sans-serif !important;
  border-radius: 6px !important;
}

.mat-calendar-body-cell {
  &:hover {
    .mat-calendar-body-cell-content {
      background-color: var(--color-primary-600);
      color: white;
    }
  }

  .mat-calendar-body-cell-content {
    border-radius: 6px !important;
  }
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  border-color: var(--color-primary-600);
}

.mat-datepicker-close-button {
  display: hidden;
}
