@use '@angular/material' as mat;

@import 'variables';

// Default Vendure light theme. The Clarity component custom properties
// are left as their defaults.
:root [cds-theme] {
  // Colors
  --color-grey-100: #b5b5be;
  --color-grey-200: #4f4f4f;
  --color-grey-300: #828282;
  --color-grey-400: #a8b3c4;
  --color-grey-500: #525456;
  --color-grey-600: #8292aa;
  --color-grey-700: #797979;
  --color-grey-800: #242b35;
  --color-grey-900: #8c929b;

  --color-gray-400: #858585;
  --color-gray-700: #333333;
  --color-gray-800: #3d4046;

  --color-white: #fff;
  --color-white-fd: #fdfdfd;
  --color-white-f0: #f0f0f0;
  --color-white-f7: #f6f6f7;
  --color-white-ee: #eeeeee;
  --color-white-ef: #e9ecef;
  --color-black: #000;

  --color-weight-100: #e1e1e1;
  --color-weight-125: #d9d9d9;
  --color-weight-150: #f3f3f3;
  --color-weight-200: #e0e4ea;
  --color-weight-300: #d0d5dd;
  --color-weight-400: #c8ced9;
  --color-weight-500: #a1aebe;
  --color-weight-600: #5b6b86;
  --color-weight-700: #464f60;
  --color-weight-800: #242b35;
  --color-weight-900: #3a424a;
  --color-weight-950: #344054;
  --color-weight-975: #384252;
  --color-weight-1000: #121212;

  --color-primary-100: #f5faff;
  --color-primary-125: #d8f2fd;
  --color-primary-150: #e0f2fe;
  --color-primary-200: #edfaff;
  --color-primary-300: #0ba5ec;
  --color-primary-400: #59d0ff;
  --color-primary-500: #58b0e3;
  --color-primary-600: #16b4f3;
  --color-primary-700: #0076c0;
  --color-primary-800: hsl(196 100% 25%);
  --color-primary-900: hsl(196 100% 15%);
  --color-primary-950: hsl(196 100% 10%);
  --color-primary-975: hsl(196 100% 7%);
  --color-primary-1000: hsl(196 100 5%);

  --color-accent-100: hsl(35 100% 95%);
  --color-accent-125: hsl(35 100% 93%);
  --color-accent-150: hsl(35 100% 90%);
  --color-accent-200: hsl(35 100% 85%);
  --color-accent-300: hsl(35 100% 75%);
  --color-accent-400: hsl(35 100% 65%);
  --color-accent-500: hsl(35 100% 55%);
  --color-accent-600: hsl(35 100% 45%);
  --color-accent-700: hsl(35 100% 35%);
  --color-accent-800: hsl(35 100% 25%);
  --color-accent-900: hsl(35 100% 15%);
  --color-accent-950: hsl(35 100% 10%);
  --color-accent-975: hsl(35 100% 7%);
  --color-accent-1000: hsl(35 100% 5%);

  --color-success-100: hsl(158 76% 95%);
  --color-success-125: hsl(158 76% 93%);
  --color-success-150: hsl(158 76% 90%);
  --color-success-200: hsl(158 76% 85%);
  --color-success-300: hsl(158 76% 75%);
  --color-success-400: hsl(158 76% 65%);
  --color-success-500: hsl(158 76% 55%);
  --color-success-600: hsl(158 76% 45%);
  --color-success-700: #12b76a;
  --color-success-800: hsl(158 76% 25%);
  --color-success-900: hsl(158 76% 15%);
  --color-success-950: hsl(158 76% 10%);
  --color-success-975: hsl(158 76% 7%);
  --color-success-1000: hsl(158 76% 5%);

  --color-warning-100: hsl(53 100% 95%);
  --color-warning-125: hsl(53 100% 93%);
  --color-warning-150: hsl(53 100% 90%);
  --color-warning-200: hsl(53 100% 85%);
  --color-warning-300: hsl(53 100% 75%);
  --color-warning-400: hsl(53 100% 65%);
  --color-warning-500: hsl(53, 100%, 47%);
  --color-warning-600: #f2c639;
  --color-warning-700: hsl(53 100% 35%);
  --color-warning-800: hsl(53 100% 25%);
  --color-warning-900: hsl(53 100% 15%);
  --color-warning-950: hsl(53 100% 10%);
  --color-warning-975: hsl(53 100% 7%);
  --color-warning-1000: hsl(53 100% 5%);

  --color-error-100: hsl(4 100% 95%);
  --color-error-125: hsl(4 100% 93%);
  --color-error-150: hsl(4 100% 90%);
  --color-error-200: hsl(4 100% 85%);
  --color-error-300: hsl(4 100% 75%);
  --color-error-400: hsl(4 100% 65%);
  --color-error-500: #f04438;
  --color-error-600: hsl(4 100% 45%);
  --color-error-700: #c50f1f;
  --color-error-800: hsl(4 100% 25%);
  --color-error-900: hsl(4 100% 15%);
  --color-error-950: hsl(4 100% 10%);
  --color-error-975: hsl(4 100% 7%);
  --color-error-1000: hsl(4 100% 5%);

  --color-pink-100: #fdedf4;
  --color-pink-200: #f17eb2;

  // Checkbox
  --clr-forms-checkbox-background-color: var(--color-primary-600);

  // Universal semantic colors
  --color-component-bg-100: var(--color-grey-100);
  --color-component-bg-200: var(--color-grey-200);
  --color-component-bg-300: var(--color-grey-300);
  --color-component-border-100: var(--color-grey-200);
  --color-component-border-200: var(--color-grey-300);
  --color-component-border-300: var(--color-grey-400);
  --color-text-100: var(--clr-global-font-color);
  --color-text-200: var(--clr-global-font-color-secondary);
  --color-text-300: var(--color-grey-400);
  --clr-global-font-color: hsl(198, 0%, 40%);
  --clr-list-item-color: var(--clr-global-font-color);
  --color-text-inverse: white;
  --color-text-active: var(--color-primary-800);

  // Component-specific colors
  --color-scrollbar-bg: var(--color-weight-150);
  --color-scrollbar-thumb: var(--color-weight-300);
  --color-scrollbar-thumb-hover: var(--color-weight-400);

  --color-top-bar-bg: white;
  --color-card-border: white;
  --color-card-bg: white;

  --color-left-nav-bg: var(--color-primary-100);
  --color-left-nav-text: var(--color-text);
  --color-left-nav-text-hover: var(--color-primary-700);
  --color-surface-bg: var(--color-white-fd);
  --color-page-header: white;
  --color-page-header-item-bg: var(--color-weight-125);

  --color-button-bg: hsl(0 0% 98%);
  --color-button-hover-bg: var(--color-weight-100);
  --color-button-ghost-bg: white;
  --color-button-ghost-text: var(--color-text-100);
  --color-button-ghost-hover-text: var(--color-primary-700);
  --color-button-ghost-hover-bg: var(--color-primary-100);
  --color-button-small-bg: var(--color-weight-150);
  --color-button-small-hover-bg: var(--color-weight-200);
  --color-button-small-text: var(--color-weight-700);

  --color-channel-switcher-bg: var(--color-weight-150);
  --color-channel-switcher-hover-bg: var(--color-weight-125);

  --color-icon-button: var(--color-grey-600);
  --color-icon-button-hover: var(--color-primary-600);
  --color-form-input-bg: white;
  --color-form-input-focus: var(--color-primary-100);

  --color-chip-warning-border: var(--color-warning-500);
  --color-chip-warning-text: var(--color-warning-800);
  --color-chip-warning-bg: var(--color-warning-150);
  --color-chip-success-border: var(--color-success-200);
  --color-chip-success-text: var(--color-success-800);
  --color-chip-success-bg: var(--color-success-150);
  --color-chip-error-border: var(--color-error-200);
  --color-chip-error-text: var(--color-error-800);
  --color-chip-error-bg: var(--color-error-150);

  --color-json-editor-background-color: var(--color-grey-200);
  --color-json-editor-text: var(--color-grey-600);
  --color-json-editor-string: var(--color-secondary-600);
  --color-json-editor-number: var(--color-primary-700);
  --color-json-editor-boolean: var(--color-primary-700);
  --color-json-editor-null: var(--color-grey-500);
  --color-json-editor-key: var(--color-success-800);
  --color-json-editor-error: var(--color-error-700);

  --color-table-header-border: var(--color-weight-200);
  --color-table-row-hover-bg: var(--color-weight-100);
  --color-table-row-active-bg: var(--color-primary-100);
  --color-table-row-separator: var(--color-weight-100);
  --data-table-filter-box-shadow: inset -1px 6px 5px 0px rgb(165 165 165 / 8%);

  --color-split-view-separator-border: var(--color-weight-150);
  --color-split-view-separator-resize-border: var(--color-primary-400);

  --color-dropdown-item-focus-outline: rgba(77, 207, 255, 0.53);

  // Layout
  --layout-content-max-width: 1400px;
  --left-nav-width: 0px;
  --surface-width: 100vw;
  --surface-margin-left: 8px;

  @media screen and (min-width: $breakpoint-small) {
    //--left-nav-width: 100px;
    //--surface-width: calc(100vw - 100px);
    //--surface-margin-left: 4px;
  }

  @media screen and (min-width: $breakpoint-medium) {
    --left-nav-width: 250px;
    --surface-width: calc(100vw - 250px);
    --surface-margin-left: calc(var(--space-unit) * 2);
  }

  @media screen and (min-width: $breakpoint-large) {
    --left-nav-width: 280px;
    --surface-width: calc(100vw - 280px);
    --surface-margin-left: calc(var(--space-unit) * 3);
  }

  // Login page
  --login-wrapper-inner-bg: var(--color-white);
  --login-page-bg: var(--color-white-fd);
  --color-login-gradient-top: var(--color-primary-800);
  --color-login-gradient-bottom: black;

  // Border radius
  --clr-global-borderradius: 8px;
  --border-radius-sm: calc(var(--clr-global-borderradius) / 2);
  --border-radius: var(--clr-global-borderradius);
  --border-radius-lg: calc(var(--space-unit) * 3);
  --border-radius-img: var(--clr-global-borderradius);
  --border-radius-input: var(--clr-global-borderradius);

  // typography
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --cds-global-typography-body-line-height: 24px;
  --cds-alias-typography-body-line-height: 16px;
  --cds-alias-typography-body-letter-spacing: normal;
  --cds-alias-typography-display-letter-spacing: normal;
  --cds-alias-typography-secondary-font-size: 13px;
  --cds-alias-typography-font-size-3: var(--font-size-sm);
  --cds-global-typography-font-size-4: var(
    --cds-alias-typography-secondary-font-size
  );

  // spacing
  --space-unit: 8px;

  // letter spacing
  --letter-spacing-xs: 0.5px;
  --letter-spacing-sm: 0.75px;
  --letter-spacing-base: 1px;
  --letter-spacing-lg: 1.25px;
  --letter-spacing-xl: 1.5px;

  // clarity styles
  --clr-link-visited-color: var(--color-weight-700);
  --clr-link-hover-color: var(--color-weight-700);
  --clr-link-active-color: var(--color-weight-700);
  --clr-link-color: var(--color-weight-700);
  --clr-list-item-color: var(--color-weight-700);

  --cds-global-space-7: 20px;

  // custom material styles
  --mat-datepicker-calendar-date-today-outline-color: var(--color-primary-600);
  --mat-datepicker-calendar-date-hover-state-background-color: var(
    --color-primary-600
  );
  --mat-datepicker-calendar-date-selected-state-background-color: var(
    --color-primary-600
  );
  --mat-datepicker-toggle-active-state-icon-color: var(--color-primary-600);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(
    --color-primary-125
  );

  // custom progress bar
  --clr-progress-bg-color: var(--color-weight-125);
  --clr-progress-default-color: var(--color-warning-600);

  // stepper
  --mat-stepper-header-hover-state-layer-color: transparent;

  $bv-brand: (
    50: #f5faff,
    100: #d8f2fd,
    200: #e0f2fe,
    300: #0ba5ec,
    400: #59d0ff,
    500: #58b0e3,
    600: #16b4f3,
    700: #0076c0,
    800: hsl(196 100% 25%),
    900: hsl(196 100% 15%),
    A100: #f5faff,
    A200: #d8f2fd,
    A400: #59d0ff,
    A700: #0076c0,
    contrast: (
      50: white,
      100: white,
      200: white,
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: white,
      A200: white,
      A400: white,
      A700: white
    )
  );

  $default-theme: mat.define-light-theme(
    (
      color: (
        primary: mat.define-palette($bv-brand),
        accent: mat.define-palette($bv-brand)
      ),
      typography: (
        mat.define-typography-config(
          $font-family: 'BeVietnamPro',
          $headline-1:
            mat.define-typography-level(
              112px,
              112px,
              300,
              var(--letter-spacing-xl)
            ),
          $headline-2:
            mat.define-typography-level(
              56px,
              56px,
              300,
              var(--letter-spacing-lg)
            ),
          $headline-3:
            mat.define-typography-level(
              45px,
              48px,
              400,
              var(--letter-spacing-lg)
            ),
          $headline-4:
            mat.define-typography-level(
              34px,
              40px,
              400,
              var(--letter-spacing-base)
            ),
          $headline-5:
            mat.define-typography-level(
              24px,
              32px,
              400,
              var(--letter-spacing-base)
            ),
          $headline-6:
            mat.define-typography-level(
              20px,
              32px,
              500,
              var(--letter-spacing-sm)
            ),
          $subtitle-1:
            mat.define-typography-level(
              16px,
              24px,
              400,
              var(--letter-spacing-sm)
            ),
          $subtitle-2:
            mat.define-typography-level(
              14px,
              24px,
              500,
              var(--letter-spacing-sm)
            ),
          $body-1:
            mat.define-typography-level(
              16px,
              24px,
              400,
              var(--letter-spacing-base)
            ),
          $body-2:
            mat.define-typography-level(
              14px,
              24px,
              400,
              var(--letter-spacing-base)
            ),
          $button:
            mat.define-typography-level(
              14px,
              14px,
              500,
              var(--letter-spacing-lg)
            ),
          $caption:
            mat.define-typography-level(
              12px,
              20px,
              400,
              var(--letter-spacing-xs)
            ),
          $overline:
            mat.define-typography-level(
              10px,
              16px,
              400,
              var(--letter-spacing-xs)
            )
        )
      ),
      density: 0
    )
  );

  @include mat.all-component-themes($default-theme);
}
