@font-face {
  font-family: 'BeVietnamPro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./BeVietnamPro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'BeVietnamPro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./BeVietnamPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'BeVietnamPro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./BeVietnamPro-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'BeVietnamPro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./BeVietnamPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'BeVietnamPro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./BeVietnamPro-Bold.ttf') format('truetype');
}
