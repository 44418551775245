html,
body:not([cds-text]) {
  font-size: var(--font-size-sm);
  font-family: BeVietnamPro, sans-serif !important;
  line-height: var(--cds-global-typography-body-line-height);
  color: var(--color-text-100);
}

body {
  font-family: BeVietnamPro, sans-serif !important;
  font-size: var(--font-size-base);
  font-weight: 400;
  line-height: 20px;
}

body p:not([cds-text]) {
  font-family: BeVietnamPro, sans-serif !important;
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-corner {
  background-color: var(--color-scrollbar-bg);
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-scrollbar-thumb);
  border: 2px solid var(--color-scrollbar-bg);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-scrollbar-thumb-hover);
}

::-webkit-scrollbar-track {
  background-color: var(--color-scrollbar-bg);
}

/* Hide toggle password icon in Edge */
::-ms-reveal {
  display: none;
}
