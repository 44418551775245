input:not([type="checkbox"]):not([type="radio"]).input,
textarea.input {
  &.primary {
    padding: 10px 20px !important;
    border-radius: 8px !important;
    color: var(--color-grey-900);
    height: 42px;

    &::placeholder {
      color: var(--color-grey-400);
      font-size: var(--font-size-sm);
      line-height: 1rem;
      font-weight: 300;
    }

    &.input-search {
      padding: 6px 32px 6px 16px !important;
    }
  }

  &:read-only {
    background-color: var(--color-white-f7);
    color: var(--color-grey-900);
  }
}

textarea.input {
  height: auto !important;
}
