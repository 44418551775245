.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
  > .mat-mdc-tab-header
  .mat-mdc-tab {
  flex-grow: 0 !important;
  --mdc-secondary-navigation-tab-container-height: 40px;

  .mdc-tab__text-label {
    @include text_style;
  }
}

.mat-mdc-tab-labels {
  border-bottom: 1px solid var(--color-weight-400);
}

.mat-mdc-tab-body-content {
  padding: 20px 0;
}

.mdc-tab {
  border-top-left-radius: var(--clr-global-borderradius);
  border-top-right-radius: var(--clr-global-borderradius);
  overflow: hidden;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
  border-top-left-radius: var(--clr-global-borderradius) !important;
  border-top-right-radius: var(--clr-global-borderradius) !important;
}
