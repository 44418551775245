.actions-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    border-radius: 100rem !important;
    width: 28px;
    height: 28px;
    padding: 0;

    img {
      width: 18px;
      height: 18px;
    }
  }
}

.status-radio-group {
  display: flex;
  align-items: center;
  gap: 24px;
}

.table-link-col {
  font-weight: 600;
  color: var(--color-primary-600) !important;
}

.group-actions {
  display: flex;
  gap: 16px;
}
