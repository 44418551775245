.ng-select .ng-select-container {
  height: 42px !important;
}

.ng-select.ng-select-multiple {
  .ng-select-container .ng-value-container {
    .ng-placeholder {
      top: 12px;
    }

    .ng-value.ng-star-inserted {
      margin-bottom: 3px;
      display: flex;
      flex-direction: row-reverse;
      background-color: var(--color-weight-200) !important;
      border-radius: 12px;

      .ng-value-icon {
        padding-left: 0px;
        border: none;
      }
    }
  }
}
