.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--color-white-f0);

  .btn-close {
    cursor: pointer;
    padding: 4px;
  }

  .dialog-title {
    font-size: var(--font-size-lg);
    font-weight: 500;
  }
}

.dialog-content {
  padding: 16px;
  border-bottom: 1px solid var(--color-white-f0);
  display: grid;
  gap: 20px;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-sm);

  .icn {
    display: flex;
    justify-content: center;
    color: var(--color-error-500);
  }
}

.dialog-footer {
  padding: 16px;
  display: flex;
  justify-content: center;
  flex: 1;
  gap: 20px;

  button {
    min-width: 120px !important;
    border-radius: 12px;
  }
}
