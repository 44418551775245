.mat-horizontal-content-container {
  padding: 0 !important;
}

.mat-horizontal-stepper-header {
  height: auto !important;
  cursor: default;
  pointer-events: none;
}

.mat-horizontal-stepper-header-container {
  width: fit-content;
}

.mat-step-header:hover:not([aria-disabled]),
.mat-step-header:hover[aria-disabled='false'] {
  background-color: transparent !important;
}

mat-icon.mat-icon {
  display: none;
}

.mat-step-icon {
  width: 32px !important;
  height: 32px !important;
  background-color: transparent !important;
  border: 2px solid;
  border-color: var(--color-weight-500);
  color: var(--color-weight-500) !important;

  &.mat-step-icon-selected {
    border-color: var(--color-primary-600);
    color: var(--color-primary-600) !important;
  }
}

.mat-step-label {
  font-weight: 500 !important;

  &.mat-step-label-selected {
    color: var(--color-primary-600) !important;
  }
}

.mat-step-icon-state-edit {
  background-color: var(--color-pink-200) !important;
  border-color: var(--color-pink-200) !important;
  color: white !important;
}

.mat-step-label-active {
  color: var(--color-pink-200) !important;
}

.isOldPage {
  .mat-horizontal-stepper-header-container {
    display: none;
  }
}

mat-step-header .mat-step-label {
  overflow: visible;
}

mat-step-header .mat-step-icon-not-touched span,
mat-step-header .mat-step-icon span,
mat-step-header .mat-step-icon-not-touched .mat-icon,
mat-step-header .mat-step-icon .mat-icon {
  display: none;
}

mat-step-header:nth-of-type(1) .mat-step-icon-not-touched:after,
mat-step-header:nth-of-type(1) .mat-step-icon:after {
  content: '01';
  font-weight: 500;
  inset: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

mat-step-header:nth-of-type(2) .mat-step-icon-not-touched:after,
mat-step-header:nth-of-type(2) .mat-step-icon:after {
  content: '02';
  font-weight: 500;
  inset: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
