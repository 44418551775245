.mat-accordion {
  padding: 12px;
  .mat-expansion-panel {
    margin-bottom: 12px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: unset;

    &:not([class*="mat-elevation-z"]) {
      box-shadow: none;
      background: transparent;
      border: 1px solid var(--color-weight-200);
      border-radius: 12px !important;

      &:hover {
        background: transparent;
      }
    }

    .mat-expansion-panel-header {
      user-select: none;
      padding: 12px;

      .mat-content {
        justify-content: space-between;
      }

      .mat-expansion-panel-header-description {
        justify-content: flex-end;
        margin: 0;
      }
    }

    &:not(.mat-expanded) .mat-expansion-panel-header {
      &:not([aria-disabled="true"]):hover {
        background: transparent;
      }
    }
  }

  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 0 12px 12px;
    }
  }
}
