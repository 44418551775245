@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../../../../node_modules/angular-calendar/css/angular-calendar.css';

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'global/sass-overrides';
@import 'global/clarity';
@import '@cds/core/global.min.css';
@import '@clr/icons/clr-icons.min.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'global/buttons';
@import 'global/inputs';
@import 'global/selects';
@import 'global/forms';
@import 'global/customs';
@import 'global/date-range';
@import 'global/mat-accordion';
@import 'global/mat-tab';
@import 'global/select';
@import 'global/mat-step';
@import 'global/date-picker';
@import 'global/dialog';
@import 'global/timepicker';
@import 'global/overrides';
@import 'global/utilities';
@import 'fonts';
@import 'global/global';
@import 'global/custom-class';
@import 'component/prosemirror';

@import 'theme/default';
@import 'theme/dark';

@import 'rtl';
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
